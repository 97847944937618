import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { FaWhatsapp } from 'react-icons/fa';
import Modal from 'react-modal';

const DetalheImovel = () => {
    const location = useLocation();
    const imovel = location.state;
    
    const [imagemPrincipal, setImagemPrincipal] = useState(imovel.images[0].image);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    if (!imovel) return <p>Imóvel não encontrado!</p>;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    // Função para abrir o modal
    const openModal = () => setModalIsOpen(true);
    
    // Função para fechar o modal
    const closeModal = () => setModalIsOpen(false);

    // Função para gerar o link do WhatsApp com a mensagem padrão
    const generateWhatsAppLink = () => {
        const message = `Olá, estou interessado no imóvel ${imovel.title} com ID ${imovel.id}. Poderia me fornecer mais informações?`;
        return `https://wa.me/5542998093469?text=${encodeURIComponent(message)}`; // Substitua o número de telefone.
    };
    const formatPrice = (price: number) => {
        return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };
    const formarDescription = (description: string) => {
        return description.replace(/\n/g, '<br />');
    }

    return (
        <>
        <div className="faixa-details">
            <h1 style={{
                color: '#fff',
                textAlign: 'center',
                fontSize: '34px',
                fontWeight: 'bold',
            }}>Detalhes do Imóvel</h1>
        </div>
        
        <div className="detalhe-imovel">
            {/* Galeria principal */}
            <div className="container-details">
                <div className="galeria-imovel">
                    <img 
                        src={'https://diegocorretor.com/painel/public/'+imagemPrincipal} 
                        alt={imovel.title} 
                        className="imagem-principal fade-in" 
                        onClick={openModal}  // Abre o modal ao clicar na imagem principal
                    />
                </div>

                {/* Miniaturas */
                imovel.images.length >= 2 &&
                <div className="miniaturas-imovel">
                    <Slider {...settings}>
                        {imovel.images.map((image:any, index:any) => (
                            <div key={index}>
                                <img 
                                    src={'https://diegocorretor.com/painel/public/'+image.image} 
                                    alt={`Miniatura ${index + 1}`} 
                                    className={`miniatura ${image === imagemPrincipal ? 'active' : ''}`} 
                                    onClick={() => setImagemPrincipal(image.image)} 
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
}
            </div>

            {/* Informações do imóvel */}
            <div className="info-imovel">
                <h1>{imovel.title}</h1>
                <p>{imovel.address}</p>
                <p>Preço: { formatPrice(imovel.price) }</p>
                <p>Quartos: {imovel.beds}</p>
                <p>Banheiros: {imovel.baths}</p>
                <p>Tamanho: {imovel.size} m²</p>
                
                {/* Botão de contato */}
                <div className="contato">
                    <a href={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
                        <FaWhatsapp color='#fff' size={24} />
                        Falar no WhatsApp
                    </a>
                </div>
            </div>
         
        </div>

        {/* Descrição do imóvel */}
        <div className="description">
            <h2>Descrição</h2>
            <p dangerouslySetInnerHTML={{ __html: formarDescription(imovel.description) }} />
        </div>

        {/* Modal para a imagem em tamanho grande */}
        <Modal 
            isOpen={modalIsOpen} 
            onRequestClose={closeModal} 
            style={{
                overlay: {
                    zIndex: 1001,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                content: {
                    position: 'relative',
                    inset: 'auto',
                    width: '90%',
                    height: '90%',
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    borderRadius: '8px',
                    padding: 0,
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }
            }
            
            contentLabel="Imagem Grande"
            className="modal-imagem-grande"
        >
            <img src={'https://diegocorretor.com/painel/public/'+imagemPrincipal} alt="Imagem Ampliada" className="imagem-modal fade-in" />
            {imovel.images.length >= 2 &&
            <div className="miniaturas-imovel-modal">
                    <Slider {...settings}>
                        {imovel.images.map((image:any, index:any) => (
                            <div key={index}>
                                <img 
                                    src={'https://diegocorretor.com/painel/public/'+image.image} 
                                    alt={`Miniatura ${index + 1}`} 
                                    className={`miniatura-modal ${image === imagemPrincipal ? 'activet' : ''}`} 
                                    onClick={() => setImagemPrincipal(image.image)} 
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
}
            <button onClick={closeModal} className="close-modal">Fechar</button>
        </Modal>
        </>
    );
};

export default DetalheImovel;
const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "25px", zIndex: 1, top: "50%" }}
            onClick={onClick}
        >
           
        </div>
    );
};

const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "5px", zIndex: 1, top: "50%" }}
            onClick={onClick}
        >
            
        </div>
    );
};
