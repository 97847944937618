import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Layout from './layoult/_layout';
import Home from './screens/Home';
import About from './screens/About'; // Página "Sobre"
import './App.css';
import RealEstate from './screens/RealEstate';
import Contact from './screens/Contact';
import DetalheImovel from './screens/RealStateDetails';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} /> {/* Página inicial */}
          <Route path="/about" element={<About />} /> {/* Página "Sobre" */}
          <Route path='/real-estate' element={<RealEstate/>} /> {/* Página "Imóveis" */}
          <Route path="/contact" element={<Contact /> } />
          <Route path="/detalhe/:id" element={<DetalheImovel />} /> 
          <Route path="*" element={<div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
            gap: '10px'
          }}>
            <h1 style={{
              fontSize: '5rem',
              margin: 0
            }}>404</h1>
            <p>Página não encontrada</p>
            <Link style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer'
            }} to="/">Voltar para a página inicial</Link>
          </div>} />
        </Routes>
      </Layout>
      <Toaster position='top-right'  />
    </Router>
  );
}

export default App;
