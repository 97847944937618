import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import './style.css';
import logo from '../../assets/logo-footer.png';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-container">

                <div className="footer-column">
                    <div className="footer-column-title">
                        <img src={'./logo-footer.png'} alt="Logo" />
                        <div className="footer-column-text">
                        <h3>Diego Oliveira</h3>
                        <p>CRECI-PR-48674</p>
                        <p>CNAI-46843</p>
                        </div>
                    </div>
                    <div className="infos">
                      <h4>Horários de atendimento:</h4>
                       <p> Seg a Sex: 08:30h às 11:30h - 13h as 17:00h</p>
                        <p>Sábados 09:00h as 12:00h</p>
                        <h4 style={{
                            marginTop: '20px'
                        }}>Endereço:</h4>
                        <p>Rua Assef Garzuze, 40 - Centro, Irati - PR
                        </p>
                    </div>
                    <div className="footer-social">
                    <Link style={{
            color: '#FFFFFF',
            textDecoration: 'none',
            cursor: 'pointer'
          }} to="https://www.instagram.com/anderson.diego.3152" target="_blank" rel="noreferrer">
            <FaInstagram size={26} />
          </Link>
          <Link
            style={{
              color: '#FFFFFF',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
            to="https://wa.me/5542998093469?text=Olá,%20tenho%20interesse%20em%20mais%20informações%20sobre%20os%20imóveis.%20Poderia%20me%20ajudar?"
            target="_blank"
            rel="noreferrer">
            <FaWhatsapp size={26} />
          </Link>
                    </div>
                </div>

            </div>


            <div className="footer-bottom">
                <p>
                    Copyright 2024, Corretor Diego Oliveira. Todos os direitos.
                </p>
                <p>Desenvolvido por <a style={{
                    color: '#fff',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                }} href='https://antifalhas.com.br' target='_blank'>ANTI-FALHAS</a></p>
            </div>
        </footer>
    );
};

export default Footer;
