import toast from 'react-hot-toast';
import api from './config';
import { send } from 'process';

export const service = () => ({
    sendContact: async (data: any) => {
        try {
            const response = await api.post('api/message', data); // A barra inicial ajuda a formar a URL corretamente
            console.log('Contact sent:', response.data);
            console.log('Data:', data);
            return response.data;
        } catch (error) {
            console.error('Error sending contact:', error);
            toast.error('Erro ao enviar mensagem de contato');
            return error;
        }
    },
    getAllRealState: async () => {
        try {
            const response = await api.get('api/properties');
            console.log('Real State:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting real state:', error);
            return error;
        }
    },
    getFilterOptions: async () => {
        try {
            const response = await api.get('api/properties-types');
            console.log('Filter Options:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting filter options:', error);
            return error;
        }
    }

});

