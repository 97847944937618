import { useEffect, useState } from "react";
import CardImovel from "../../components/CardImovel";
import Header from "../../components/Header";
import HomeBanner from "../../components/HomeBanner";
import './style.css';
import { FaBold, FaClock, FaFileAlt, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { service } from "../../service/service";
import ReactLoading  from 'react-loading';


const imovel = [{
    id: 1,
    img: "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: false,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148, // Tamanho em pés quadrados
    description: 'Lindo apartamento com vista para o rio, localizado em um dos melhores bairros da cidade. Com 4 quartos, 2 banheiros, sala de estar, sala de jantar, cozinha, área de serviço e varanda. O condomínio oferece piscina, academia, salão de festas e segurança 24 horas.'
},
{
    id: 2,
    img: "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
{
    id: 3,
    img: "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
{
    id: 4,
    img: "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
{
    id: 5,
    img: "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price: 6000,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
{
    id: 6,
    img: "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',
    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
{
    id: 7,
    img: "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
        "https://media.istockphoto.com/id/1282518866/pt/foto/3d-rendering-of-modern-house-in-luxurious-style.jpg?s=1024x1024&w=is&k=20&c=40sYEet2ww0RxVQm7OUFKbFk2bPlX47WmEbN_WdPQKo=",
        "https://media.istockphoto.com/id/1335855785/pt/foto/modern-home-exterior.jpg?s=1024x1024&w=is&k=20&c=dD61uX08IT4h-YfrN6c8T3mnKiDlXdcwEkAje92b7zA=",
        "https://media.istockphoto.com/id/1151042175/pt/foto/backyard-patio.jpg?s=1024x1024&w=is&k=20&c=hEvppLrjnpXe3xME43jxfAFpztKtCME6m3XMsFs9u3I="
    ],
    title: 'Riverview Retreat',

    price:6000 ,
    address: 'Rua das Flores, 123 - Vila Feliz - SP',
    city: 'São Paulo',
    district: 'Vila Feliz',
    forRent: true,  // Ou false, para venda
    type: 'Apartamento',
    beds: 4,
    baths: 2,
    size: 2148 // Tamanho em pés quadrados
},
];

const Home = () => {
  

    const [imoveisParaExibir, setImoveisParaExibir] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const api = service();
    const navigate = useNavigate();

    const getImoveis = async () => {
        setLoading(true);
        const response: any = await api.getAllRealState();
        if (response && !response.status && response.length > 0) {
            setImoveisParaExibir(response);
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        getImoveis();
    }, []);

    const updateImoveisParaExibir = () => {
        const tamanho = window.innerWidth;
        if (tamanho > 700) {
            setImoveisParaExibir(imoveisParaExibir.slice(0, 6));
        } else if (tamanho < 500) {
            setImoveisParaExibir(imoveisParaExibir.slice(0, 3));
        } else {
            setImoveisParaExibir(imoveisParaExibir.slice(0, 4)); // Ajuste conforme necessário
        }
    };

    useEffect(() => {
        document.title = "Diego Oliveira | Início";
        updateImoveisParaExibir(); // Atualiza a lista de imóveis ao carregar a página

        window.addEventListener('resize', updateImoveisParaExibir); // Adiciona o event listener para o resize
        return () => {
            window.removeEventListener('resize', updateImoveisParaExibir); // Remove o event listener ao desmontar o componente
        };
    }, []);
    return (

        <>
       
        {loading && (
        <div className="loading-overlay">
          <ReactLoading type="spin" color="#7E6E12" height={80} width={80} />
        </div>
      )}
            <HomeBanner />
            <section className="imoveis-disponiveis" >
                <h2>Imóveis Disponíveis</h2>
                <div className="cards">
                    {imoveisParaExibir.length > 0 && imoveisParaExibir ? imoveisParaExibir.map((imovel:any) => (
                        <Link to={{
                            pathname:`/detalhe/${imovel.id}`,
                            
                            
                            }} state={imovel}  style={{ textDecoration: 'none' }}>
                        <CardImovel key={imovel.id} imovel={imovel} />
                        </Link>
                    )): <p>Nenhum imóvel disponível no momento</p>}

                </div>
                <button onClick={()=>
                    navigate('/real-estate')
                } className="ver-mais">Ver mais</button>

            </section>
            <section className="faixa">
                <h2>Encontre seu imóvel dos sonhos</h2>
            </section>

            <section className="balao-sobre">
            <div className="balao-sobre-imagem">
                    <img src="https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Imagem de uma casa" />
                </div>
                <div className="balao-sobre-texto">
                    <h2>Quem somos</h2>
                    <p>
                        Somos uma empresa especializada em encontrar o imóvel perfeito para você.
                        Com uma equipe de corretores altamente qualificados, estamos prontos para
                        atender todas as suas necessidades.
                    </p>
                    <Link  className="ver-mais" to={"/about"}>Saiba mais</Link>
                </div>
            </section>

            <section className="why-choose-us">
                <h2>Por que escolher seu imóvel conosco?</h2>
                <div className="cards-wcu">
                    <div className="card-wcu">
                        <FaUser size={48} />
                        <h3>Atendimento personalizado</h3>
                        <p>Foco nas suas necessidades, com soluções personalizadas.</p>
                    </div>
                    <div className="card-wcu">
                        <FaClock size={48} />
                        <h3>Experiência no Mercado</h3>
                        <p>Com a nossa experiência, garantimos a melhor negociação.</p>
                    </div>
                    <div className="card-wcu">
                        <FaFileAlt size={48} />
                        <h3>Transparência em todas as Etapas</h3>
                        <p>Negociações claras e seguras, sem surpresas.</p>
                    </div>
                    <div className="card-wcu">
                        <FaBold size={48} />
                        <h3>Agilidade no Atendimento</h3>
                    <p>Rapidez na resposta e eficiência para fechar negócios.</p>
                </div>
            </div>
        </section>

                       
        </>
    );
}

export default Home;