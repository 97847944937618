import React, { useEffect, useState } from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneSquareAlt, FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi"; // Ícones de abrir/fechar o menu
import './style.css';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';



const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  return (
    <header className="header">
      <div className="header-top">
        <div className="header-info">

          <p className="email"> <MdOutlineMailOutline size={20} />&nbsp;&nbsp; comercial@diegocorretor.com</p>

          <p className="phone"><FaPhoneSquareAlt size={20} />&nbsp;&nbsp; (42) 99809-3469</p>
        </div>
        <div className="header-social">
          <Link style={{
            color: '#FFFFFF',
            textDecoration: 'none',
            cursor: 'pointer'
          }} to="https://www.instagram.com/anderson.diego.3152" target="_blank" rel="noreferrer">
            <FaInstagram size={20} />
          </Link>
          <Link
            style={{
              color: '#FFFFFF',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
            to="https://wa.me/5542998093469?text=Olá,%20tenho%20interesse%20em%20mais%20informações%20sobre%20os%20imóveis.%20Poderia%20me%20ajudar?"
            target="_blank"
            rel="noreferrer">
            <FaWhatsapp size={20} />
          </Link>
         
        </div>
      </div>
      <div className="header-bottom">
        <div className="logo">
          <img src={'./logo.png'} alt="Logo" />

        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? <FiX className='fechar' size={30} /> : <FiMenu size={30} />}
        </button>
        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Início</Link></li>

            <li><Link to="/real-estate" onClick={closeMenu}>Imóveis</Link></li>
            <li><Link to="/about" onClick={closeMenu}>Sobre</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contato</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
