// src/services/axiosConfig.ts
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://diegocorretor.com/painel/public/', // Verifique a URL base
  timeout: 10000, // 10 segundos para garantir o tempo suficiente
  headers: {
    'Content-Type': 'application/json',
    //'Secret-Key': 'sW1x+z5N6R9Yj9w5KlMbFzK5iJNu+5fKuR6pD8v0yKM='
    'Authorization': 'Bearer sW1x+z5N6R9Yj9w5KlMbFzK5iJNu+5fKuR6pD8v0yKM=' 
  }
});
/*
api.interceptors.request.use(
  (config) => {
    console.log('Request config:', config);
    config.headers['Content-Type'] = 'application/json';
    config.headers['Allow-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Headers'] = '*';
    config.headers['secret-key'] = 'sW1x+z5N6R9Yj9w5KlMbFzK5iJNu+5fKuR6pD8v0yKM=';
    return config;
  },
  (error) => Promise.reject(error)
);
*/
export default api;
