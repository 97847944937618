import './style.css';
import style from './../../../node_modules/dom-helpers/esm/css.d';
import { FaBullseye, FaEye, FaHandHoldingHeart } from 'react-icons/fa';

const About = () => {
    return (
        <>
        <section className="faixa-sobre">
        <h2>Sobre</h2>
        
    </section>
    <section className="sobre">
        <p>Diego é um corretor de imóveis com anos de experiência no mercado, dedicado a encontrar o lar ideal para cada cliente. Com um atendimento personalizado, ele garante a melhor experiência na compra e venda de imóveis, sempre com foco em confiança e transparência.</p>
            
            <p style={{
                fontWeight: 'bold',
            }}>Se você está procurando um novo lar, entre em contato para agendar uma visita e encontrar a casa dos seus sonhos.</p>
            <div className="cards-sobre">
                <div className="card-sobre">
                    <FaBullseye size={48} />
                    <h3 style={{marginTop: 5}}>Missão</h3>
                    <div className="divider"></div>
                    <p>Oferecer serviços de excelência na intermediação de imóveis, ajudando cada cliente a encontrar o imóvel perfeito com segurança e agilidade.</p>
                </div>
                <div className="card-sobre">
                    <FaEye size={48} />
                    <h3 style={{marginTop:5}}>Visão</h3>
                    <div className="divider"></div>
                    <p>Ser referência no mercado imobiliário, reconhecido pela ética, inovação e qualidade no atendimento.</p>
                </div>
                <div className="card-sobre">
                    <FaHandHoldingHeart size={48} />
                    <h3 style={{marginTop: 5}}>Valores</h3>
                    <div className="divider"></div>
                    <ul>
                        <li>Ética</li>
                        <li>Transparência</li>
                        <li>Comprometimento</li>
                        <li>Inovação</li>
                        <li>Foco no cliente</li>
                    </ul>
                </div>
            </div>
        </section>
        </>
    );
    }

export default About;