import './style.css';
import { FaBed, FaBath } from 'react-icons/fa'; // Ícones de cama e banheiro
import { MdSquareFoot } from 'react-icons/md';  // Ícone de metros quadrados
import { FaLocationDot } from "react-icons/fa6";
import style from './../../../node_modules/dom-helpers/esm/css.d';

const CardImovel = ({ imovel }: { imovel: any }) => {
    const formatPrice = (price: number) => {
        return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };
    return (
        <>

            {imovel &&

                <div className="imovel-card">

                    <img className='' src={imovel.image ? 'https://diegocorretor.com/painel/public/' + imovel.image : 'https://placehold.co/600x400'} alt={imovel.title} />


                    <div className="imovel-info">
                        <div className="tags">
                            <span className={`tag ${imovel.purpose === 'Venda' ? 'for-sell' : 'for-rent'}`}>
                                {imovel.purpose === 'Venda' ? 'Vender' : 'Alugar'}
                            </span>
                            <span className="tag">{imovel.types.name}</span>
                        </div>

                        <h3>{imovel.title}</h3>
                        <p className="price">{formatPrice(imovel.price)} {imovel.purpose == 'Alugar' ? 'Mês' : '' }</p>
                        <div className='divider-1'></div>
                        <p className="address"> <FaLocationDot /> {imovel.address}</p>


                        {<div className="details">
                            <div className="detail-item">
                                <span>Quartos</span>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <FaBed style={{
                                        marginRight: '5px'
                                    }} size={14} />
                                    <span style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>{imovel.beds}</span>
                                </div>
                            </div>
                            <div className='divider-2'></div>
                            <div className="detail-item">
                                <span>Banheiros</span>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <FaBath style={{
                                        marginRight: '5px'
                                    }} size={14} />
                                    <span style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}> {imovel.baths}</span>
                                </div>
                            </div>
                            <div className='divider-2'></div>
                            <div className="detail-item">
                                <span>Tamanho</span>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <MdSquareFoot style={{
                                        marginRight: '5px'
                                    }} size={16} />
                                    <span style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}> {imovel.size} m² </span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    <button className="view-details">Ver detalhes</button>
                </div>}
        </>
    );
};

export default CardImovel;
