import Carousel from "react-bootstrap/Carousel";
import banner1 from "../../assets/banner1.jpeg";
import "./style.css";
import { useEffect, useState } from "react";



const HomeBanner = () => {
  const [dataBanners, setDataBanners] = useState<any>([]);

  useEffect(() => {
    fetch('./bannerDetails.json')
      .then(response => response.json())
      .then(data => setDataBanners(data))
      .catch(error => console.error('Error loading banner details:', error));
  }, []);

  return (
  
      <Carousel controls={true}>
      
          {dataBanners.map((banner:any, index:any) => ( 
              <Carousel.Item key={index} >
            <img className="banner" src={`./${banner.image}`} alt="" key={index} style={{
              width: "100%",
            }}/>
            <div className="carousel-caption">
            <h3>{banner.title}</h3>
            <p>{banner.title}</p>
          </div>
 </Carousel.Item>
          ))

          /*
          <img className="banner" src={banner1} alt="" style={{
            width: "100%",
          }}/>
          <div className="carousel-caption">
            <h3>Encontre o imóvel perfeito</h3>
            <p>Descubra opções incríveis que se encaixam no seu orçamento.</p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className="banner" src={banner1} alt="" />
          <div className="carousel-caption">
            <h3>Explore novas oportunidades</h3>
            <p>Viva em um lugar que você ama, com conforto e estilo.</p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className="banner" src={banner1} alt="" />
          <div className="carousel-caption">
            <h3>Realize o sonho da casa própria</h3>
            <p>Encontre sua casa ideal com a House Hunter.</p>
          </div>
          */
        }
       

      </Carousel>
   
  );
};

export default HomeBanner;