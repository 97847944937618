import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import './style.css';
import Select from "react-select";
import CardImovel from "../../components/CardImovel";
import { Link } from "react-router-dom";
import { service } from "../../service/service";
import ReactLoading from 'react-loading';

const RealEstate = () => {
    const [filteredImoveis, setFilteredImoveis] = useState<any>([]);
    const [imoveis, setImoveis] = useState<any>([]); // Para armazenar todos os imóveis
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<{
        cities: { value: string, label: string }[],
        districts: { value: string, label: string }[],
        types: { value: string, label: string }[],
        selectedCity: { value: string, label: string } | null,
        selectedDistrict: { value: string, label: string } | null,
        selectedType: { value: string, label: string } | null,
    }>({
        cities: [],
        districts: [],
        types: [],
        selectedCity: null,
        selectedDistrict: null,
        selectedType: null,
    });
    const api = service();

    const handleFilterChange = (selectedOption: any, filterKey: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterKey]: selectedOption,
        }));
    };

    const getImoveis = async () => {
        setLoading(true);
        const response = await api.getAllRealState();
        if (response && !response.status && response.length > 0) {
            setImoveis(response); // Armazena todos os imóveis
            setFilteredImoveis(response); // Inicializa com todos os imóveis
            setLoading(false);
        }
        setLoading(false);
    };

    const getFilterOptions = async () => {
        const response = await api.getFilterOptions();
        if (response && response.data) {
            const citiesOptions = Array.isArray(response.data.cities) ? response.data.cities.map((city: any) => ({
                value: city,
                label: city,
            })) : [];
            
            const districtsOptions = Array.isArray(response.data.districts) ? response.data.districts.map((district: any) => ({
                value: district,
                label: district,
            })) : [];

            const typesOptions = Array.isArray(response.data.types) ? response.data.types.map((type: any) => ({
                value: type.id,
                label: type.name,
            })) : [];

            setFilters(prevFilters => ({
                ...prevFilters,
                cities: citiesOptions,
                districts: districtsOptions,
                types: typesOptions,
            }));
        }
    };

    useEffect(() => {
        getFilterOptions();
        getImoveis();
    }, []);

    const filterImoveis = () => {
        let filtered = imoveis;
        if (filters.selectedCity) {
            filtered = filtered.filter((imovel: any) => filters.selectedCity && imovel.city.toLowerCase() == filters.selectedCity.value);
            console.log(filters.selectedCity);
        if (filters.selectedDistrict) {
            filtered = filtered.filter((imovel: any) => filters.selectedDistrict && imovel.district.toLowerCase() == filters.selectedDistrict.value);
        }
        if (filters.selectedType) {
          
                filtered = filtered.filter((imovel: any) => filters.selectedType && imovel.types.id == filters.selectedType.value);
            
        }

        console.log('Filtered:', filtered);
        setFilteredImoveis(filtered);
    };
    }
    return (
        <>
        {loading && (
        <div className="loading-overlay">
          <ReactLoading type="spin" color="#7E6E12" height={80} width={80} />
        </div>
      )}
            <div className="faixa-real-estate">
                <h1><FaSearch size={34} color="#fff" /> Encontre um imóvel</h1>
                <div className="real-estate-filtros">
                    <div className="coluna">
                        <div className="item">
                            <label htmlFor="cidade">Cidade</label>
                            <Select
                                id="cidade"
                                className="select"
                                styles={{
                                    control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', height: '25px', borderRadius: '5px' }),
                                }}
                                options={filters.cities} // Usando cidades obtidas da API
                                placeholder="Selecione..."
                                onChange={(selectedOption) => handleFilterChange(selectedOption, "selectedCity")}
                            />
                        </div>
                    </div>
                    <div className="coluna">
                        <div className="item">
                            <label htmlFor="bairro">Bairro</label>
                            <Select
                                id="bairro"
                                className="select"
                                styles={{
                                    control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', height: '25px', borderRadius: '5px' }),
                                }}
                                options={filters.districts} // Usando distritos obtidos da API
                                placeholder="Selecione..."
                                onChange={(selectedOption) => handleFilterChange(selectedOption, "selectedDistrict")}
                            />
                        </div>
                    </div>
                    <div className="coluna">
                        <div className="item">
                            <label htmlFor="tipo">Tipo</label>
                            <Select
                                id="tipo"
                                className="select"
                                styles={{
                                    control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', height: '25px', borderRadius: '5px' }),
                                }}
                                options={filters.types} // Usando tipos obtidos da API
                                placeholder="Selecione..."
                                onChange={(selectedOption) => handleFilterChange(selectedOption, "selectedType")}
                            />
                        </div>
                    </div>
                </div>
                <button onClick={filterImoveis} className="filter-button">Filtrar</button>
            </div>

            <div className="imoveis-list">
                {filteredImoveis.length > 0 ? (
                    filteredImoveis.map((imovel: any) => (
                        <Link to={`/detalhe/${imovel.id}`} state={imovel} style={{ textDecoration: 'none' }} key={imovel.id}>
                            <CardImovel imovel={imovel} />
                        </Link>
                    ))
                ) : (
                    <p>Nenhum imóvel encontrado com os filtros selecionados.</p>
                )}
            </div>
        </>
    );
};

export default RealEstate;
