import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './style.css';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { service } from '../../service/service';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';

const Contact = () => {
    const [loading, setLoading] = useState(false);  
    const [errors, setErrors] = useState({
        email: '',
        phone: '',
    });
    const [phone, setPhone] = useState('');
const api = service();

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone: string) => {
        const phoneRegex = /^\(\d{2}\)\d{5}-\d{4}$/;
        return phoneRegex.test(phone);
    };
    const handlePhoneChange = (e:any) => {
        const { value } = e.target;
        const phone = value.replace(/\D/g, '');
        if (phone.length > 10) {
            e.target.value = phone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else {
            e.target.value = phone.replace(/^(\d{2})(\d{0,5})/, '($1) $2');
        }
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            clientName: (e.target as any).name.value,
            clientEmail: (e.target as any).email.value,
            clientPhone: phone,
            message: (e.target as any).message.value,
        };

        let valid = true;
        let emailError = '';
        let phoneError = '';

        if (!validateEmail(formData.clientEmail)) {
            emailError = 'Email inválido';
            valid = false;
        }

        if (!validatePhone(formData.clientPhone)) {
            phoneError = 'Número de celular inválido. Formato esperado: (12) 12345-1234';
            valid = false;
        }
        if(formData.clientName === '' || formData.clientPhone === '' || formData.clientEmail === '' || formData.message === ''){
            toast.error('Preencha todos os campos!');
            valid = false;
        }

        setErrors({ email: emailError, phone: phoneError });

        if (valid) {
            console.log('Form Data:', formData);
            try {

                const res = await api.sendContact(formData);
                if (res) {
                    toast.success('Mensagem enviada com sucesso!');
                    (e.target as any).reset();
                    setPhone('');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
            // Aqui você pode enviar os dados do formulário para o servidor ou realizar outras ações
        }
    };

    return (
        <>
        {loading && (
        <div className="loading-overlay">
          <ReactLoading type="spin" color="#7E6E12" height={80} width={80} />
        </div>
      )}
        <div className="contact-container">
            <div className="contact-info">
                <h2>Contate-nos</h2>
                <p>Entre em contato conosco e agende uma visita sem compromisso!</p>
                <p><strong>Endereço:</strong> Rua Assef Garzuze, 40 - Centro, Irati - PR</p>
                <p><strong>Telefone:</strong> (42) 99809-3469</p>
                <p><strong>E-mail:</strong> comercial@diegocorretor.com </p>
                <div className="social-icons">
                    <Link style={{
                        color: '#7E6E12',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }} to="https://www.instagram.com/anderson.diego.3152" target="_blank">
                        <FaInstagram size={26} />
                    </Link>
                    <Link
                        style={{
                            color: '#7E6E12',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                        to="https://wa.me/5542998093469?text=Olá,%20tenho%20interesse%20em%20mais%20informações%20sobre%20os%20imóveis.%20Poderia%20me%20ajudar?"
                        target="_blank"
                    >
                        <FaWhatsapp size={26} />
                    </Link>
                </div>
            </div>
            <div className="contact-form">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Nome</label>
                    <input type="text" id="name" name="name" placeholder="Nome" required />

                    <label htmlFor="phone">Número</label>
                    <InputMask
                        mask="(99)99999-9999"
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="Ex: (12) 12345-1234"
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }
                        }
                        required

                        
                    >
                        
                    </InputMask>
                    {errors.phone && <p className="error">{errors.phone}</p>}

                    <label htmlFor="email">E-mail</label>
                    <input type="email" id="email" name="email" placeholder="Ex: john@gmail.com" required />
                    {errors.email && <p className="error">{errors.email}</p>}

                    <label htmlFor="message">Mensagem</label>
                    <textarea id="message" name="message" placeholder="Escreva sua mensagem aqui..." required></textarea>

                    <button type="submit" className="submit-btn">ENVIAR</button>
                </form>
            </div>
        </div>
        </>
    );
};

export default Contact;
